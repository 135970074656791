@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");

.luckyDress {
  background: rgb(12, 53, 70);
  background: radial-gradient(
    circle,
    rgba(12, 53, 70, 1) 0%,
    rgb(1, 1, 16) 40%,
    rgb(0, 0, 0) 100%
  );
  width: 100vw;
  height: fit-content;
  overflow: hidden;
}

.luckyDress p {
  cursor: default;
}

.luckyMainBody {
  width: 100vw;
  height: var(--doc-height);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.LunarDate {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ascending {
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 18.5vh;
  line-height: 18vh;
  color: #fff7dc;
  margin: 0;
}

.moonDate {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LunarDateCount {
  width: fit-content;
  height: fit-content;
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 29.5vh;
  line-height: 22vh;
  color: #fff7dc;
  margin: 0;
}

.night {
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 14.5vh;
  line-height: 20vh;
  color: #fff7dc;
  margin: 0;
}

.fortuneFortold {
  width: fit-content;
  height: fit-content;
  font-family: "IBM Plex Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 4.5vh;
  color: #fff7dc;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 428px) {
  .luckyMainBody {
    flex-direction: column;
  }

  .LunarDate {
    width: 95vw;
    height: 40vh;
    margin: 0 0 4vh 5vw;
    align-items: flex-start;
  }

  .ascending {
    font-size: 10.5vh;
    line-height: 14vh;
    margin-top: 5vh;
  }

  .moonDate {
    flex-direction: row;
    align-items: baseline;
  }

  .LunarDateCount {
    width: fit-content;
    font-size: 16.5vh;
    text-align: center;
    line-height: 10vh;
  }

  .night {
    font-size: 8.5vh;
    line-height: 8vh;
  }

  .fortuneFortold {
    width: fit-content;
    height: 40vh;
    font-size: 3vh;
    margin: 4vh 0 2vh 0;
    padding: 0 5vw 0 5vw;
    text-align: right;
    min-width: 90vw;
  }
}
