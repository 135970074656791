@font-face {
  font-family: "RuneScape Quill";
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  src: url("../font/runescapes/runescape/otf/RuneScape-Quill.otf")
    format("opentype");
}

.DDE {
  background-color: #000000;
  width: 100vw;
  min-height: 15vh;
  display: flex;
  justify-content: space-between;
  position: relative;
  scroll-snap-align: end;
  z-index: 300;
  cursor: url(./torch_dark.cur), auto;
  text-align: center;
}

.Typewriter {
  font-family: RuneScape Quill;
  font-style: normal;
  font-weight: normal;
  font-size: 8.4vh;
  line-height: 9.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff00;
  text-shadow: #ffffff 1px 0 10px;
}

.Typewriter a :hover {
  text-decoration: none;
}

.Torch1 {
  position: relative;
  height: 15vh;
  width: auto;
  transform: scaleX(-1);
}

.Torch2 {
  position: relative;
  height: 15vh;
  width: auto;
}

@media screen and (max-width: 428px) {
  .DDE {
    justify-content: space-between;
    text-align: center;
  }

  .Typewriter {
    font-family: terminal-grotesque-open;
    font-style: normal;
    font-weight: normal;
    font-size: 4vh;
    line-height: 2.5vh;
    display: flex;
    align-items: center;
    color: #f3f3f3;
    text-shadow: #ffffff 1px 0 10px;
    width: 30vw;
  }
}
