@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.Zing {
  background-color: #0057FF;
  min-height: 100vh;
  display: flex;
  position: relative;
  width: 100%;
  scroll-snap-align: end;
}

.ZingLogo {
  position: absolute;
  width: 23vw;
  height: auto;
  left: 62.5vw;
  top: 16.3vh;
}

.Text1 {
  position: absolute;
  width: 19.6vw;
  height: 11.6vh;
  left: 62.5vw;
  bottom: 22.5vh;
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  color: #000000;
}

.FishTankMain {
  position: absolute;
  width: 41.1vw;
  height: auto;
  left: 29.1vh;
  top: 11.1vh;
  z-index: 6;
}

.John {
  position: absolute;
  width: 17.4vw;
  height: auto;
  left: 8vw;
  bottom: 0;
  z-index: 8;
}

.Smith {
  transform: scaleX(-1);
  position: absolute;
  width: 14vw;
  height: auto;
  left: 67.1vw;
  bottom: 35.5vh;
}

.LzingBoi {
  position: absolute;
  width: 13vw;
  height: auto;
  right: 0;
  bottom: 0;
}

.myarrow1 {
  position: absolute;
  left: 73vw;
  bottom: 12.6vh;
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.line1 {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #47E473;
  margin: 10px 0;
  transition: width 1s ease-out
}

.line1::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .line1:hover {
    width: 15.6vw;
  }
}

.triangle1 {
  display: flex;
  flex-direction: column;
}

.right1 {
  transform: rotate(45deg);
  transform-origin: top right;
  border-right: 0.15rem solid #47E473;
  height: 1rem;
}

.left1 {
  transform: rotate(-45deg);
  transform-origin: bottom right;
  border-left: 0.15rem solid #47E473;
  height: 1rem;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

@media screen and (max-width: 428px) {
  .ZingLogo {
    position: absolute;
    width: 63vw;
    height: auto;
    left: 16.6vw;
    top: 12vh;
  }

  .FishTankMain {
    position: absolute;
    width: 73.6vw;
    height: auto;
    left: 16.6vw;
    top: 27vh;
  }

  .Text1 {
    position: absolute;
    width: 165px;
    height: 60px;
    left: 48.4vw;
    top: 64vh;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .Smith {
    display: none;
    visibility: hidden;
  }

  .John {
    position: absolute;
    width: 163px;
    height: 146px;
    left: 33px;
    bottom: 0;
  }

  .LzingBoi {
    position: absolute;
    width: 45vw;
    height: auto;
    left: 55vw;
    top: 80vh;
  }

  .myarrow1 {
    position: absolute;
    left: 52.7vw;
    bottom: 2.6vh;
  }

  .line1 {
    display: inline-block;
    width: 41.6vw;
    height: 2px;
    margin: 10px 0;
  }
}