@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.FriendshipContent {
  width: auto;
  height: 100vh;
  overflow: unset;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
}

.FriendshipContent::-webkit-scrollbar {
  height: 0;
  visibility: hidden;
}

.FriendHScroll {
  scrollbar-width: thin;
  scrollbar-color: #67FDF4 #1F2245;
}

.FriendHScroll::-webkit-scrollbar {
  height: 12px;
}

.FriendHScroll::-webkit-scrollbar-track {
  background-color: #1F2245;
}

.FriendHScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #67FDF4;
  border: 2px solid #1F2245;
}

.BackArrowFriendship {
  width: auto;
  height: auto;
  position: fixed;
  right: 84.3vw;
  top: 6.3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.lineBFriendship {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #67FDF4;
  margin: 10px 0;
  box-shadow: 0 0 10px 2px #FFFFFF;
  transition: width 1s ease-out
}

.lineBFriendship::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .lineBFriendship:hover {
    width: 15.6vw;
  }
}

.triangleBFriendship {
  display: flex;
  flex-direction: column;
}

.rightBFriendship {
  transform: rotate(-45deg);
  transform-origin: top left;
  border-left: 0.15rem solid #67FDF4;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

.leftBFriendship {
  transform: rotate(45deg);
  transform-origin: bottom left;
  border-right: 0.15rem solid #67FDF4;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

p {
  margin: 0;
}

.FriendshipContent1 {
  width: 300vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #1F2245, #1F2245), linear-gradient(to right, #FFFF00, #FFFF00), linear-gradient(to right, #F13939, #F13939);
  background-position: 0 0, 100vw 0, 200vw 0;
  background-size: 100vw 100vh, 100vw 100vh, 100vw 100vh;
}

.friendshipFont {
  position: absolute;
  width: 25.7vw;
  height: auto;
  left: 5.6vw;
  top: 12.9vh;
}

.friendshipText1 {
  position: absolute;
  width: 23.6vw;
  height: 48.2vh;
  left: 30.7vw;
  top: 41.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.friendshipText2 {
  position: absolute;
  width: 12.2vw;
  height: 4.6vh;
  left: 76.9vw;
  top: 17.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #FFFFFF;
}

.friendshipText3 {
  position: absolute;
  width: 19.11vw;
  height: 16.2vh;
  left: 210vw;
  top: 21.4vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFF00;
}

.friendshipText4 {
  position: absolute;
  width: 22.2vw;
  height: 11.1vh;
  left: 266.6vw;
  top: 71.4vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFF00;
}

.friendshipVid {
  position: absolute;
  width: 53.5vw;
  height: 55.8vh;
  left: 110.3vw;
  top: 14.6vh;
}

.friendshipPic1 {
  position: absolute;
  width: 13.2vw;
  height: auto;
  left: 59.1vw;
  top: 12.1vh;
}

.friendshipPic2 {
  position: absolute;
  width: 28.75vw;
  height: auto;
  left: 66.4vw;
  top: 53.1vh;
}

.friendshipPic3 {
  position: absolute;
  width: 17.5vw;
  height: auto;
  left: 173.6vw;
  top: 9.8vh;
}

.friendshipPic4 {
  position: absolute;
  width: 39.3vw;
  height: auto;
  left: 180.3vw;
  top: 49.2vh;
}

.friendshipPic5 {
  position: absolute;
  width: 24.5vw;
  height: auto;
  left: 236.2vw;
  top: 9.8vh;
}

.friendshipPic6 {
  position: absolute;
  width: 23.6vw;
  height: auto;
  left: 269.2vw;
  top: 15.4vh;
}

@media screen and (max-width: 428px) {
  .BackArrowFriendship {
    right: 46.1vw;
    top: 5vh;
  }

  .lineBFriendship {
    width: 41.6vw;
  }

  .FriendshipContent1 {
    width: 700vw;
    background-position: 0 0, 200vw 0, 400vw 0;
    background-size: 200vw 100vh, 200vw 100vh, 300vw 100vh;
  }

  .friendshipFont {
    position: absolute;
    width: 77.4vw;
    height: auto;
    left: 11.7vw;
    top: 11.1vh;
  }

  .friendshipText1 {
    position: absolute;
    width: 73vw;
    height: 43vh;
    left: 54.3vw;
    top: 48vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .friendshipText2 {
    position: absolute;
    width: 21vw;
    height: 7.1vh;
    left: 156.9vw;
    top: 42vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .friendshipText3 {
    position: absolute;
    width: 58.9vw;
    height: 12.2vh;
    left: 423.8vw;
    top: 22.6vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .friendshipText4 {
    position: absolute;
    width: 58.9vw;
    height: 8.8vh;
    left: 610.7vw;
    top: 55.3vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .friendshipVid {
    position: absolute;
    width: 89.7vw;
    height: 23.8vh;
    left: 233.5vw;
    top: 42.7vh;
  }

  .friendshipPic1 {
    position: absolute;
    width: 65.1vw;
    height: auto;
    left: 157.4vw;
    top: 12.3vh;
  }

  .friendshipPic2 {
    position: absolute;
    width: 76.6vw;
    height: auto;
    left: 142vw;
    top: 66.5vh;
  }

  .friendshipPic3 {
    position: absolute;
    width: 68.2vw;
    height: auto;
    left: 315.8vw;
    top: 11.2vh;
  }

  .friendshipPic4 {
    position: absolute;
    width: 141.5vw;
    height: auto;
    left: 334.3vw;
    top: 59.7vh;
  }

  .friendshipPic5 {
    position: absolute;
    width: 87.1vw;
    height: auto;
    left: 503vw;
    top: 15.8vh;
  }

  .friendshipPic6 {
    position: absolute;
    width: 55.6vw;
    height: auto;
    left: 622.5vw;
    top: 22.9vh;
  }
}