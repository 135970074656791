@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'ThunderLight';
  src: url('../font/Fonts/Web-PS/Thunder-LightLC.woff2') format('woff2'),
    /* Modern Browsers */
    url('../font/Fonts/Web-PS/Thunder-LightLC.otf') format('opentype');
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.AhShit {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  scroll-snap-align: end;
}

.AhShitMainFont {
  position: absolute;
  width: 20.6vw;
  height: auto;
  left: 14vw;
  top: 12.3vh;
  margin: 0;
}

.AhShitFontMobile {
  display: none;
  visibility: hidden;
}

.Year4 {
  position: absolute;
  width: 10.3vw;
  height: 13.3vh;
  left: 33.4vw;
  top: 56.7vh;
  font-family: 'ThunderLight';
  font-style: normal;
  font-weight: 300;
  font-size: 10.5vh;
  line-height: 9.8vh;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #14FFFF;
  margin: 0;
}

.CoverImg4 {
  position: absolute;
  width: 51.7vw;
  height: auto;
  left: 47vw;
  top: 20.5vh;
}

.Text4 {
  position: absolute;
  width: 17.7vw;
  height: 11.9vh;
  left: 14vw;
  top: 76vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  color: #FFFFFF;
  margin: 0;
}

.myarrow4 {
  position: absolute;
  left: 33.4vw;
  bottom: 9.8vh;
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.line4 {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #14FFFF;
  margin: 10px 0;
  transition: width 1s ease-out
}

.line4::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .line4:hover {
    width: 15.6vw;
  }
}

.triangle4 {
  display: flex;
  flex-direction: column;
}

.right4 {
  transform: rotate(45deg);
  transform-origin: top right;
  border-right: 0.15rem solid #14FFFF;
  height: 1rem;
}

.left4 {
  transform: rotate(-45deg);
  transform-origin: bottom right;
  border-left: 0.15rem solid #14FFFF;
  height: 1rem;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

@media screen and (max-width: 428px) {
  .Year4 {
    position: absolute;
    width: 16.6vw;
    height: 4.6vh;
    left: 75vw;
    top: 4.6vh;
    font-style: normal;
    font-weight: 300;
    font-size: 4.6vh;
    line-height: 4.3vh;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .AhShitMainFont {
    display: none;
    visibility: hidden;
  }

  .AhShitFontMobile {
    position: absolute;
    width: 46.8vw;
    height: auto;
    left: 16.6vw;
    top: 10vh;
    margin: 0;
    display: unset;
    visibility: unset;
  }

  .CoverImg4 {
    position: absolute;
    width: 80.8vw;
    height: auto;
    left: 15.2vw;
    top: 45.3vh;
  }

  .Text4 {
    position: absolute;
    width: 52.7vw;
    height: 9.2vh;
    left: 16.6vw;
    top: 75vh;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .myarrow4 {
    position: absolute;
    left: 52.7vw;
    bottom: 2.6vh;
  }

  .line4 {
    display: inline-block;
    width: 41.6vw;
    height: 2px;
    margin: 10px 0;
  }
}