@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

@font-face {
  font-family: 'Athelas Regular';
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  src: local('Athelas Regular'), url('../font/Noto-Serif/Athelas-Regular.woff') format('woff');
}

.IWillContent {
  width: auto;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
}

.IWillContent::-webkit-scrollbar {
  height: 0;
  visibility: hidden;
}

.IWillHScroll {
  scrollbar-width: thin;
  scrollbar-color: #5582B8 #B9C0BE;
}

.IWillHScroll::-webkit-scrollbar {
  height: 12px;
}

.IWillHScroll::-webkit-scrollbar-track {
  background-color: #B9C0BE;
}

.IWillHScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #5582B8;
  border: 2px solid #B9C0BE;
}

.BackArrowIWill {
  width: auto;
  height: auto;
  position: fixed;
  right: 84.3vw;
  top: 6.3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.lineBIWill {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #5582B8;
  margin: 10px 0;
  box-shadow: 0 0 10px 2px #FFFFFF;
  transition: width 1s ease-out
}

.lineBIWill::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .lineBIWill:hover {
    width: 15.6vw;
  }
}

.triangleBIWill {
  display: flex;
  flex-direction: column;
}

.rightBIWill {
  transform: rotate(-45deg);
  transform-origin: top left;
  border-left: 0.15rem solid #5582B8;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

.leftBIWill {
  transform: rotate(45deg);
  transform-origin: bottom left;
  border-right: 0.15rem solid #5582B8;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-shadow: #Fc0 1px 0 10px;
}

.IWillContent1 a:hover {
  text-decoration: wavy underline;
}

.IWillContent1 {
  width: 1310vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #B9C0BE, #B9C0BE), linear-gradient(to right, #92B5A8, #92B5A8), linear-gradient(to right, #EDEDED, #EDEDED), linear-gradient(to right, #DDDDDD, #DDDDDD), linear-gradient(to right, #B9C0BE, #B9C0BE), linear-gradient(to right, #92B5A8, #92B5A8), linear-gradient(to right, #EDEDED, #EDEDED), linear-gradient(to right, #D2D8D6, #D2D8D6);
  background-position: 0 0, 100vw 0, 200vw 0, 300vw 0, 400vw 0, 700vw 0, 800vw 0, 1000vw 0;
  background-size: 100vw 100vh, 100vw 100vh, 100vw 100vh, 100vw 100vh, 300vw 100vh, 100vw 100vh, 200vw 100vh, 330vw 100vh;
}

.IWillFont {
  position: absolute;
  width: 14.5vw;
  height: auto;
  left: 10.5vw;
  top: 8.3vh;
}

.IWillPic1 {
  position: absolute;
  width: 27.7vw;
  height: auto;
  left: 36.3vw;
  top: 35.1vh;
}

.IWillPic2 {
  position: absolute;
  width: 31.9vw;
  height: auto;
  left: 134vw;
  top: 10vh;
}

.IWillPic3 {
  position: absolute;
  width: 30.1vw;
  height: auto;
  left: 173.6vw;
  top: 50.8vh;
}

.IWillPic4 {
  position: absolute;
  width: 27.3vw;
  height: auto;
  left: 208.4vw;
  top: 8vh;
}

.IWillPic5 {
  position: absolute;
  width: 34.3vw;
  height: auto;
  left: 252.7vw;
  top: 12vh;
}

.IWillPic6 {
  position: absolute;
  width: 24.5vw;
  height: auto;
  left: 404.3vw;
  top: 9vh;
}

.IWillPic7 {
  position: absolute;
  width: 18vw;
  height: auto;
  left: 453.75vw;
  top: 66.5vh;
}

.IWillPic8 {
  position: absolute;
  width: 26.5vw;
  height: auto;
  left: 480vw;
  top: 6.8vh;
}

.IWillPic9 {
  position: absolute;
  width: 25.4vw;
  height: auto;
  left: 510.9vw;
  top: 7.6vh;
}

.IWillPic10 {
  position: absolute;
  width: 18.6vw;
  height: auto;
  left: 547vw;
  top: 7.6vh;
}

.IWillPic11 {
  position: absolute;
  width: 23.6vw;
  height: auto;
  left: 576.3vw;
  top: 7.6vh;
}

.IWillPic12 {
  position: absolute;
  width: 27.6vw;
  height: auto;
  left: 557.3vw;
  top: 51.1vh;
}

.IWillPic13 {
  position: absolute;
  width: 14.4vw;
  height: auto;
  left: 633.6vw;
  top: 19.6vh;
}

.IWillPic14 {
  position: absolute;
  width: 42.2vw;
  height: auto;
  left: 651.7vw;
  top: 15.4vh;
}

.IWillPic15 {
  position: absolute;
  width: 26.4vw;
  height: auto;
  left: 792.7vw;
  top: 8.8vh;
}

.IWillPic16 {
  position: absolute;
  width: 33.9vw;
  height: auto;
  left: 850vw;
  top: 52.1vh;
}

.IWillPic17 {
  position: absolute;
  width: 31.8vw;
  height: auto;
  left: 871.1vw;
  top: 4.4vh;
}

.IWillPic18 {
  position: absolute;
  width: 28.3vw;
  height: auto;
  left: 909.6vw;
  top: 8vh;
}

.IWillPic19 {
  position: absolute;
  width: 29.9vw;
  height: auto;
  left: 920vw;
  top: 53.7vh;
}

.IWillPic20 {
  position: absolute;
  width: 29.3vw;
  height: auto;
  left: 962.3vw;
  top: 4.6vh;
}

.IWillPic21 {
  position: absolute;
  width: auto;
  height: 100vh;
  left: 1000vw;
  top: 0;
}

.IWillPic22 {
  position: absolute;
  width: 29vw;
  height: auto;
  left: 1096.6vw;
  top: 24.2vh;
}

.IWillPic23 {
  position: absolute;
  width: auto;
  height: 100vh;
  left: 1130vw;
  top: 0px;
  z-index: 1;
  display: unset;
  visibility: visible;
}

.IWillPic24 {
  display: none;
  visibility: hidden;
}

.IWillPaperMock img {
  opacity: 0;
  z-index: 3;
  animation: fadeIn 100s;
  -webkit-animation: fadeIn 100s;
  -moz-animation: fadeIn 100s;
  -o-animation: fadeIn 100s;
  -ms-animation: fadeIn 100s;
}

.GlassMagnifierIMG {
  width: 92.5vw;
  height: 67.5vh;
  left: 210.6vw;
  top: 19.2vh;
  opacity: 100;
  z-index: 3;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    display: none;
    visibility: hidden;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

.IWillPaperMockBlank {
  position: absolute;
  width: 92.5vw;
  height: 67.5vh;
  left: 303.125vw;
  top: 19.2vh;
  z-index: 2;
}

.IWill132QRVid {
  position: absolute;
  width: 66.6vw;
  height: 76vh;
  left: 716.1vw;
  top: 8.8vh;
}

.IWillText1 {
  position: absolute;
  width: 22.7vw;
  height: 25.4vh;
  left: 69vw;
  top: 64.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.IWillAW1 {
  position: absolute;
  width: 23.75vw;
  height: 5.2vh;
  left: 106.6vw;
  top: 41.2vh;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.IWillAW11 {
  height: 5.2vh;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 5.7vh;
  text-align: justify;
  color: #395D77;
}

.IWillAW12 {
  height: 5.2vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.75vh;
  text-align: justify;
  color: #395D77;
}

.IWillAW1Text1 {
  position: absolute;
  width: 22.2vw;
  height: 18.7vh;
  left: 106.6vw;
  top: 50vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  ;
  color: #000000;
}

.IWillAW1Text2 {
  position: absolute;
  width: 22.1vw;
  height: 20vh;
  left: 140.5vw;
  top: 65.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW1Text3 {
  position: absolute;
  width: 12.8vw;
  height: 4.6vh;
  left: 168.95vw;
  top: 18.69vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
  padding-left: 1.04vw;
  border-left: 1px solid #000000;
}

.IWillAW2 {
  position: absolute;
  width: 19.01vw;
  height: 18.9vh;
  left: 215.05vw;
  top: 38.7vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.5vh;
  color: #557790;
}

.IWillAW2Text1 {
  position: absolute;
  width: 20.7vw;
  height: 17.1vh;
  left: 223.9vw;
  top: 65.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW2Text2 {
  position: absolute;
  width: 25.5vw;
  height: 21.9vh;
  left: 258.9vw;
  top: 68.9vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillMockupsText {
  position: absolute;
  width: 91.3vw;
  height: 2.1vh;
  left: 303.9vw;
  top: 12.03vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
}

.IWillAW3 {
  position: absolute;
  width: 14.01vw;
  height: 23.02vh;
  left: 431.77vw;
  top: 9.29vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  color: #557790;
}

.IWillAW3Text1 {
  position: absolute;
  width: 20.1vw;
  height: 28.2vh;
  left: 431.7vw;
  top: 42.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW3Text2 {
  position: absolute;
  width: 18.07vw;
  height: 14.04vh;
  left: 453.75vw;
  top: 42.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW3Text3 {
  position: absolute;
  width: 11.7vw;
  height: 7.4vh;
  left: 464.3vw;
  top: 7.6vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  color: #000000;
  padding-left: 1.04vw;
  border-left: 1px solid #000000;
}

.IWillAW4 {
  position: absolute;
  width: 27.2vw;
  height: 24.1vh;
  left: 510.9vw;
  top: 48.1vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  color: #557790;
}

.IWillAW4Text1 {
  position: absolute;
  width: 23.125vw;
  height: 11.4vh;
  left: 527.8vw;
  top: 78.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW5 {
  position: absolute;
  width: 28.07vw;
  height: 4.01vh;
  left: 603.6vw;
  top: 19.6vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  text-align: justify;
  color: #557790;
}

.IWillAW5Text1 {
  position: absolute;
  width: 34.8vw;
  height: 14.5vh;
  left: 589.89vw;
  top: 63.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW5Text2 {
  position: absolute;
  width: 4.6vw;
  height: 6.3vh;
  left: 624.73vw;
  top: 44vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
  padding-left: 1.04vw;
  border-left: 1px solid #000000;
}

.IWillRecordText {
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: 716.1vw;
  top: 86.9vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
}

.IWillAW6 {
  position: absolute;
  width: 14.1vw;
  height: 15.2vh;
  left: 822.39vw;
  top: 14.88vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  color: #557790;
}

.IWillAW6Text1 {
  position: absolute;
  width: 25.1vw;
  height: 16.6vh;
  left: 816vw;
  top: 72.5vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-indent: 2.6vw;
  text-align: justify;
  color: #000000;
}

.IWillAW6Text2 {
  position: absolute;
  width: 9.1vw;
  height: 4.8vh;
  left: 850vw;
  top: 42.7vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  color: #000000;
  padding-left: 1.04vw;
  border-left: 1px solid #000000;
}

.IWillInstallationView {
  position: absolute;
  width: 8.3vw;
  height: 2.5vh;
  left: 944.89vw;
  top: 24.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  color: #000000;
}

.IWillAW7 {
  position: absolute;
  width: 10.9vw;
  height: 13.9vh;
  left: 1044.2vw;
  top: 11vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  text-align: justify;
  color: #557790;
}

.IWillAW71 {
  position: absolute;
  width: 10.9vw;
  height: 9.7vh;
  left: 1053.4vw;
  top: 29.4vh;
  font-family: Athelas;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vh;
  line-height: 4.7vh;
  text-align: justify;
  color: #82A5BE;
}

.IWillAW7Text1 {
  position: absolute;
  width: 20.6vw;
  height: 42.1vh;
  left: 1044.2vw;
  top: 50.3vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW7Text2 {
  position: absolute;
  width: 22.5vw;
  height: 18.9vh;
  left: 1069.47vw;
  top: 50.3vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
}

.IWillAW7Text3 {
  position: absolute;
  width: 17.7vw;
  height: 4.4vh;
  left: 1096.6vw;
  top: 14.1vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
}

.IWillAW7Text3 span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.IWillAW7Text4 {
  position: absolute;
  width: 17.6vw;
  height: 2.3vh;
  left: 1144.42vw;
  top: 22.4vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
  z-index: 2;
}

.IWillAW7Text4 span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.IWillAW7Text5 {
  position: absolute;
  width: 24.375vw;
  height: 11.9vh;
  left: 1197.3vw;
  top: 61.1vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #000000;
  z-index: 2;
}

.IWillAW7Text5 span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

@media screen and (max-width: 428px) {
  .BackArrowIWill {
    right: 46.1vw;
    top: 5vh;
  }

  .lineBIWill {
    width: 41.6vw;
  }

  .IWillContent1 {
    width: 4140vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    position: relative;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, #B9C0BE, #B9C0BE),
      linear-gradient(to right, #92B5A8, #92B5A8),
      linear-gradient(to right, #EDEDED, #EDEDED),
      linear-gradient(to right, #DDDDDD, #DDDDDD),
      linear-gradient(to right, #B9C0BE, #B9C0BE),
      linear-gradient(to right, #92B5A8, #92B5A8),
      linear-gradient(to right, #EDEDED, #EDEDED),
      linear-gradient(to right, #D2D8D6, #D2D8D6);
    background-position: 0 0,
      200vw 0,
      500vw 0,
      800vw 0,
      1200vw 0,
      2600vw 0,
      2700vw 0,
      3400vw 0;
    background-size: 200vw 100vh,
      300vw 100vh,
      300vw 100vh,
      400vw 100vh,
      1400vw 100vh,
      100vw 100vh,
      700vw 100vh,
      740vw 100vh;
  }

  .IWillFont {
    position: absolute;
    width: 37.1vw;
    height: auto;
    left: 22.5vw;
    top: 16.5vh;
  }

  .IWillPic1 {
    position: absolute;
    width: 126.9vw;
    height: auto;
    left: 36.6vw;
    top: 60.1vh;
  }

  .IWillPic2 {
    position: absolute;
    width: 102.8vw;
    height: auto;
    left: 257.1vw;
    top: 27.1vh;
  }

  .IWillPic3 {
    position: absolute;
    width: 76.6vw;
    height: auto;
    left: 398.4vw;
    top: 20.7vh;
  }

  .IWillPic4 {
    position: absolute;
    width: 125.6vw;
    height: auto;
    left: 437.1vw;
    top: 59.4vh;
  }

  .IWillPic5 {
    position: absolute;
    width: 125.6vw;
    height: auto;
    left: 596.41vw;
    top: 11.1vh;
  }

  .IWillPic6 {
    position: absolute;
    width: 97.1vw;
    height: auto;
    left: 1220.1vw;
    top: 13.8vh;
  }

  .IWillPic7 {
    position: absolute;
    width: 88.9vw;
    height: auto;
    left: 1419.2vw;
    top: 61.6vh;
  }

  .IWillPic8 {
    position: absolute;
    width: 100vw;
    height: auto;
    left: 1576.6vw;
    top: 9.1vh;
  }

  .IWillPic9 {
    position: absolute;
    width: 120vw;
    height: auto;
    left: 1720vw;
    top: 9.1vh;
  }

  .IWillPic10 {
    position: absolute;
    width: 94vw;
    height: auto;
    left: 1879.7vw;
    top: 9.1vh;
  }

  .IWillPic11 {
    position: absolute;
    width: 120vw;
    height: auto;
    left: 2029.7vw;
    top: 9.1vh;
  }

  .IWillPic12 {
    position: absolute;
    width: 120vw;
    height: auto;
    left: 1937.1vw;
    top: 54vh;
  }

  .IWillPic13 {
    position: absolute;
    width: 71.2vw;
    height: auto;
    left: 2274.6vw;
    top: 9.1vh;
  }

  .IWillPic14 {
    position: absolute;
    width: 207.9vw;
    height: auto;
    left: 2376.4vw;
    top: 11.1vh;
  }

  .IWillPic15 {
    position: absolute;
    width: 82.0vw;
    height: auto;
    left: 2704.6vw;
    top: 15vh;
  }

  .IWillPic16 {
    position: absolute;
    width: 99.7vw;
    height: auto;
    left: 2875.1vw;
    top: 61.2vh;
  }

  .IWillPic17 {
    position: absolute;
    width: 78.4vw;
    height: auto;
    left: 2906.1vw;
    top: 13vh;
  }

  .IWillPic18 {
    position: absolute;
    width: 127.6vw;
    height: auto;
    left: 3011.2vw;
    top: 9.2vh;
  }

  .IWillPic19 {
    position: absolute;
    width: 84.3vw;
    height: auto;
    left: 3034.8vw;
    top: 60.7vh;
  }

  .IWillPic20 {
    position: absolute;
    width: 117.1vw;
    height: auto;
    left: 3161.2vw;
    top: 9.2vh;
  }

  .IWillPic21 {
    position: absolute;
    width: auto;
    height: 100vh;
    left: 3317.9vw;
    top: 0;
  }

  .IWillPic22 {
    position: absolute;
    width: 87.1vw;
    height: auto;
    left: 3595.3vw;
    top: 22.2vh;
    z-index: 2;
  }

  .IWillPic23 {
    display: none;
    visibility: hidden;
  }

  .IWillPic24 {
    position: absolute;
    width: 440vw;
    height: 100vh;
    left: 3700vw;
    top: 0px;
    z-index: 1;
    display: unset;
    visibility: visible;
  }

  .GlassMagnifierIMG {
    width: 377.1vw;
    height: 66.5vh;
    left: 434.5vw;
    top: 18.4vh;
    opacity: 100;
    z-index: 3;
  }

  .IWillPaperMockBlank {
    position: absolute;
    width: 377.1vw;
    height: 66.5vh;
    left: 811.5vw;
    top: 18.4vh;
    z-index: 2;
  }

  .IWill132QRVid {
    position: absolute;
    width: 82vw;
    height: 24vh;
    left: 2609vw;
    top: 28.6vh;
  }

  .IWillText1 {
    position: absolute;
    width: 66.6vw;
    height: 24.1vh;
    left: 114.8vw;
    top: 20.3vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW1 {
    width: fit-content;
    height: fit-content;
    left: 220.7vw;
    top: 14.4vh;
  }

  .IWillAW11 {
    height: fit-content;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW12 {
    height: fit-content;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW1Text1 {
    width: 70.2vw;
    height: 16.1vh;
    left: 227.4vw;
    top: 66.1vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW1Text2 {
    width: 76.6vw;
    height: 14.2vh;
    left: 339.4vw;
    top: 70vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW1Text3 {
    width: 34vw;
    height: fit-content;
    left: 398.4vw;
    top: 51.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
    padding-left: 5vw;
  }

  .IWillAW2 {
    width: 40vw;
    height: 17.6vh;
    left: 522.5vw;
    top: 20vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW2Text1 {
    width: 56.9vw;
    height: 14.6vh;
    left: 596.4vw;
    top: 59.4vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW2Text2 {
    width: 86.9vw;
    height: 16.3vh;
    left: 687.1vw;
    top: 58.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillMockupsText {
    width: 90vw;
    height: 7.3vh;
    left: 813.3vw;
    top: 9vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .IWillAW3 {
    width: 80vw;
    height: 10.3vh;
    left: 1338vw;
    top: 18.4vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW3Text1 {
    width: 98.9vw;
    height: 14.3vh;
    left: 1338.7vw;
    top: 37.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW3Text2 {
    width: 72.3vw;
    height: 9vh;
    left: 1463.5vw;
    top: 42.8vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW3Text3 {
    width: 39.4vw;
    height: fit-content;
    left: 1504.1vw;
    top: 12.9vh;
    font-size: 1.56vh;
    line-height: 2vh;
    padding-left: 5vw;
  }

  .IWillAW4 {
    width: 74vw;
    height: 20.4vh;
    left: 1720vw;
    top: 55.4vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW4Text1 {
    width: 58.9vw;
    height: 10.7vh;
    left: 1818.9vw;
    top: 72.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW5 {
    width: fit-content;
    height: fit-content;
    left: 2169.7vw;
    top: 18.1vh;
    font-size: 4.2vh;
    line-height: unset;
  }

  .IWillAW5Text1 {
    width: 76.9vw;
    height: 16.4vh;
    left: 2137.9vw;
    top: 61.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW5Text2 {
    position: absolute;
    width: 17vw;
    height: fit-content;
    left: 2244.6vw;
    top: 42.2vh;
    font-size: 1.56vh;
    line-height: 2vh;
    padding-left: 5vw;
  }

  .IWillRecordText {
    position: absolute;
    width: 74.1vw;
    height: fit-content;
    left: 2609vw;
    top: 56.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .IWillAW6 {
    width: 55.8vw;
    height: fit-content;
    left: 2715vw;
    top: 60.7vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW6Text1 {
    width: 72vw;
    height: 15.2vh;
    left: 2782.5vw;
    top: 61.4vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW6Text2 {
    width: 37.6vw;
    height: fit-content;
    left: 2834.3vw;
    top: 40.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
    padding-left: 5vw;
  }

  .IWillInstallationView {
    width: 30vw;
    height: fit-content;
    left: 3094.1vw;
    top: 55vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .IWillAW7 {
    width: 38.7vw;
    height: 11.2vh;
    left: 3493.3vw;
    top: 26.77vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW71 {
    width: 42vw;
    height: 7.2vh;
    left: 3539.2vw;
    top: 30.8vh;
    font-size: 3.5vh;
    line-height: unset;
  }

  .IWillAW7Text1 {
    width: 69.2vw;
    height: 32.5vh;
    left: 3493.3vw;
    top: 48.8vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW7Text2 {
    width: 86.6vw;
    height: 12.4vh;
    left: 3595.3vw;
    top: 71.9vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .IWillAW7Text3 {
    position: absolute;
    width: 56.1vw;
    height: 4.9vh;
    left: 3625.1vw;
    top: 13.6vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .IWillAW7Text4 {
    position: absolute;
    width: 38.9vw;
    height: 3.5vh;
    left: 3744.8vw;
    top: 21.6vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .IWillAW7Text5 {
    position: absolute;
    width: 76.9vw;
    height: 10.7vh;
    left: 3977.4vw;
    top: 63.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

}