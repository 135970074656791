@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.AhShitContent {
  width: auto;
  height: 100vh;
  overflow: unset;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
}

.AhShitContent::-webkit-scrollbar {
  height: 0;
  visibility: hidden;
}

.AhShitHScroll {
  scrollbar-width: thin;
  scrollbar-color: #FAFF00 #57E8E8;
}

.AhShitHScroll::-webkit-scrollbar {
  height: 12px;
}

.AhShitHScroll::-webkit-scrollbar-track {
  background-color: #57E8E8;
}

.AhShitHScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #FAFF00;
  border: 2px solid #57E8E8;
}

.BackArrowAhShit {
  width: auto;
  height: auto;
  position: fixed;
  right: 84.3vw;
  top: 6.3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.lineBAhShit {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #FAFF00;
  margin: 10px 0;
  box-shadow: 0 0 10px 2px #FFFFFF;
  transition: width 1s ease-out
}

.lineBAhShit::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .lineBAhShit:hover {
    width: 15.6vw;
  }
}

.triangleBAhShit {
  display: flex;
  flex-direction: column;
}

.rightBAhShit {
  transform: rotate(-45deg);
  transform-origin: top left;
  border-left: 0.15rem solid #FAFF00;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

.leftBAhShit {
  transform: rotate(45deg);
  transform-origin: bottom left;
  border-right: 0.15rem solid #FAFF00;
  height: 1rem;
  box-shadow: 0 0 10px 1px #FFFFFF;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

p {
  margin: 0;
}

.TextLink {
  text-decoration: none;
}

.TextLink:hover {
  text-decoration: wavy underline;
}

.AhShitContent1 {
  width: 200vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #57E8E8, #57E8E8), linear-gradient(to right, #000000, #000000);
  background-position: 0 0, 100vw 0;
  background-size: 100vw 100vh, 100vw 100vh;
}

.AhShitFont {
  position: absolute;
  width: 19.2vw;
  height: auto;
  left: 9.7vw;
  top: 13.3vh;
}

.AhShitPooh {
  position: absolute;
  width: 15.8vw;
  height: 25vh;
  left: 39.2vw;
  top: 16.3vh;
  background: url("./Images/PolitePooh.jpg") no-repeat;
  background-size: contain;
}

.AhShitPooh:hover {
  background: url("./Images/PolitePoohRed.jpg") no-repeat;
  background-size: contain;
}

.AhShitText1 {
  position: absolute;
  width: 26.4vw;
  height: 27.8vh;
  left: 30.5vw;
  top: 63.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 8vw;
  color: #000000;
}

.AhShitText2 {
  position: absolute;
  width: 15.4vw;
  height: 2.5vh;
  left: 59.11vw;
  top: 31.4vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  color: #000000;
}

.AhShitText3 {
  position: absolute;
  width: 21.6vw;
  height: 19.4vh;
  left: 103.4vw;
  top: 70.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.AhShitPic1 {
  position: absolute;
  width: 20.5vw;
  height: auto;
  left: 67.7vw;
  top: 54.4vh;
}

.AhShitPic2 {
  position: absolute;
  width: 34.2vw;
  height: auto;
  left: 78.5vw;
  top: 3.8vh;
}

.AhShitVid {
  position: absolute;
  width: 67.2vw;
  height: 76.7vh;
  left: 128.5vw;
  top: 11.5vh;
}

@media screen and (max-width: 428px) {
  .BackArrowAhShit {
    right: 46.1vw;
    top: 5vh;
  }

  .lineBAhShit {
    width: 41.6vw;
  }

  .AhShitContent1 {
    width: 400vw;
    height: 100vh;
    background-position: 0 0, 200vw 0;
    background-size: 200vw 100vh, 200vw 100vh;
  }

  .AhShitFont {
    position: absolute;
    width: 62.8vw;
    height: auto;
    left: 15.6vw;
    top: 11.8vh;
  }

  .AhShitPooh {
    width: 63vw;
    height: 22.6vh;
    left: 92.5vw;
    top: 19.6vh;
  }

  .AhShitPic1 {
    width: 58.4vw;
    height: auto;
    left: 120.7vw;
    top: 63.9vh;
  }

  .AhShitPic2 {
    width: 105.6vw;
    height: auto;
    left: 169.7vw;
    top: 24.5vh;
  }

  .AhShitVid {
    width: 82vw;
    height: 21.3vh;
    left: 308.9vw;
    top: 38.2vh;
  }

  .AhShitText1 {
    width: 76.9vw;
    height: 25.2vh;
    left: 15.6vw;
    top: 58.4vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .AhShitText2 {
    width: 50.2vw;
    height: 1.6vh;
    left: 222.5vw;
    top: 59.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
    color: #57E8E8;
  }

  .AhShitText3 {
    width: 76.9vw;
    height: 15.1vh;
    left: 256.4vw;
    top: 70.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }
}