@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.AsYouContent {
  width: auto;
  height: 100vh;
  overflow: unset;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
}

.AsYouContent::-webkit-scrollbar {
  height: 0;
  visibility: hidden;
}


.AsYouHScroll {
  scrollbar-width: thin;
  scrollbar-color: #fff #F32D2D;
}

.AsYouHScroll::-webkit-scrollbar {
  height: 12px;
}

.AsYouHScroll::-webkit-scrollbar-track {
  background-color: #F32D2D;
}

.AsYouHScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #fff;
  border: 2px solid #F32D2D;
}



.BackArrowAsYou {
  width: auto;
  height: auto;
  position: fixed;
  right: 84.3vw;
  top: 6.3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.lineBAsYou {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #FFFFFF;
  margin: 10px 0;
  box-shadow: 0 0 10px 2px #F32D2D;
  transition: width 1s ease-out
}

.lineBAsYou::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .lineBAsYou:hover {
    width: 15.6vw;
  }
}

.triangleBAsYou {
  display: flex;
  flex-direction: column;
}

.rightBAsYou {
  transform: rotate(-45deg);
  transform-origin: top left;
  border-left: 0.15rem solid #FFFFFF;
  height: 1rem;
  box-shadow: 0 0 10px 1px #F32D2D;
}

.leftBAsYou {
  transform: rotate(45deg);
  transform-origin: bottom left;
  border-right: 0.15rem solid #FFFFFF;
  height: 1rem;
  box-shadow: 0 0 10px 1px #F32D2D;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

p {
  margin: 0;
}

.AsYouContent1 {
  width: 300vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #F32D2D, #F32D2D), linear-gradient(to right, #000000, #000000);
  background-position: 0 0, 100vw 0;
  background-size: 100vw 100vh, 200vw 100vh;
}

.AsYouFont1 {
  position: absolute;
  width: 14.8vw;
  height: auto;
  left: 41.3vw;
  top: 7.3vh;
}

.AsYouAbout {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 15.2vw;
  height: 12.8vh;
  left: 71vw;
  top: 8.8vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #FFFFFF;
}

.AsYouAboutBr {
  font-size: 10vh;
  font-weight: 100;
}

.AsYouAboutText {
  width: 11.3vw;
  height: 6.9vh;
  font-size: 1.9vh;
  line-height: 2.3vh;
}

.AsYouText1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 23.5vw;
  height: 50.6vh;
  left: 71vw;
  top: 41.2vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.AsYouText2 {
  position: absolute;
  width: 23.5vw;
  height: 29.8vh;
  left: 109.3vw;
  top: 62.8vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.AsYouText3 {
  position: absolute;
  width: 24.8vw;
  height: 18vh;
  left: 137vw;
  top: 62.8vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.9vh;
  line-height: 2.3vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #FFFFFF;
}

.AsYouVid {
  position: absolute;
  width: 36.5vw;
  height: 41.7vh;
  left: 157.1vw;
  top: 12.9vh;
}

.AsYouPic1 {
  position: absolute;
  width: 28.4vw;
  height: auto;
  left: 6.25vw;
  top: 5.7vh;
}

.AsYouPic2 {
  position: absolute;
  width: 34.7vw;
  height: auto;
  left: 109.3vw;
  top: 7.8vh;
}

.AsYouPic3 {
  position: absolute;
  width: 16.7vw;
  height: auto;
  left: 170.9vw;
  top: 70vh;
}

.AsYouPic4 {
  position: absolute;
  width: 10.8vw;
  height: auto;
  left: 199.7vw;
  top: 58.2vh;
}

.AsYouPic5 {
  position: absolute;
  width: 14.8vw;
  height: auto;
  left: 243.8vw;
  top: 8.9vh;
}

.AsYouPic6 {
  position: absolute;
  width: 27.9vw;
  height: auto;
  left: 224.1vw;
  top: 51.2vh;
}

.AsYouPic7 {
  position: absolute;
  width: 27.8vw;
  height: auto;
  left: 253vw;
  top: 51.2vh;
}

.AsYouGif1 {
  position: absolute;
  width: 18.75vw;
  height: auto;
  left: 273vw;
  top: 12.2vh;
}

.AsYouGif2 {
  position: absolute;
  width: 22.7vw;
  height: auto;
  left: 210.5vw;
  top: 7.8vh;
}

@media screen and (max-width: 428px) {

  .BackArrowAsYou {
    right: 46.1vw;
    top: 5vh;
  }

  .lineBAsYou {
    width: 41.6vw;
  }

  .AsYouContent1 {
    width: 800vw;
    height: 100vh;
    background-position: 0 0, 300vw 0;
    background-size: 200vw 100vh, 600vw 100vh;
  }

  .AsYouFont1 {
    position: absolute;
    width: 40vw;
    height: auto;
    left: 15.6vw;
    top: 13.3vh;
  }

  .AsYouAbout {
    width: 50.7vw;
    height: 8.6vh;
    left: 90.5vw;
    top: 85.5vh;
  }

  .AsYouAboutBr {
    font-size: 10vh;
  }

  .AsYouAboutText {
    width: 38.4vw;
    height: 5.5vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .AsYouText1 {
    width: auto;
    height: auto;
    left: 0;
    top: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1.56vh;
    line-height: 2vh;
    text-align: justify;
    text-indent: 12.8vw;
    color: #FFFFFF;
  }

  .AsYouTextBox1 {
    position: absolute;
    width: 82vw;
    height: 22.6vh;
    left: 83.8vw;
    top: 16.7vh;
  }

  .AsYouTextBox2 {
    position: absolute;
    width: 51.4vw;
    height: 22.9vh;
    left: 125.8vw;
    top: 48.5vh;
  }

  .AsYouText2 {
    width: 82vw;
    height: 21.3vh;
    left: 249vw;
    top: 62.4vh;
    font-size: 1.56vh;
    line-height: 2.2vh;
    text-indent: 12.8vw;
  }

  .AsYouText3 {
    width: 53vw;
    height: 22.6vh;
    left: 366.1vw;
    top: 24.4vh;
    font-size: 1.56vh;
    line-height: 2vh;
    text-indent: 12.8vw;
  }

  .AsYouVid {
    position: absolute;
    width: 89.7vw;
    height: 23.3vh;
    left: 355.1vw;
    top: 60.6vh;
  }

  .AsYouPic1 {
    position: absolute;
    width: 60vw;
    height: auto;
    left: 15.6vw;
    top: 50vh;
  }

  .AsYouPic2 {
    position: absolute;
    width: 125.6vw;
    height: auto;
    left: 221.2vw;
    top: 14.6vh;
  }

  .AsYouPic3 {
    position: absolute;
    width: 70.5vw;
    height: auto;
    left: 444.1vw;
    top: 19.4vh;
  }

  .AsYouPic4 {
    position: absolute;
    width: 36.6vw;
    height: auto;
    left: 488.4vw;
    top: 57.7vh;
  }

  .AsYouPic5 {
    position: absolute;
    width: 42.5vw;
    height: auto;
    left: 633.5vw;
    top: 20.7vh;
  }

  .AsYouPic6 {
    position: absolute;
    width: 100vw;
    height: auto;
    left: 564.6vw;
    top: 53.3vh;
  }

  .AsYouPic7 {
    position: absolute;
    width: 100vw;
    height: auto;
    left: 668.7vw;
    top: 53.3vh;
  }

  .AsYouGif1 {
    position: absolute;
    width: 64.8vw;
    height: auto;
    left: 538.4vw;
    top: 20.7vh;
  }

  .AsYouGif2 {
    position: absolute;
    width: 68.2vw;
    height: auto;
    left: 718.9vw;
    top: 20.7vh;
  }
}