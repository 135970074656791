@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'ThunderLight';
  src: url('../font/Fonts/Web-PS/Thunder-LightLC.woff2') format('woff2'),
    /* Modern Browsers */
    url('../font/Fonts/Web-PS/Thunder-LightLC.otf') format('opentype');
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.FriendShip {
  background-color: #F13939;
  min-height: 100vh;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  scroll-snap-align: end;
}

.FriendFont {
  position: absolute;
  width: 36.2vw;
  height: auto;
  left: 48.2vw;
  top: 6.7vh;
  margin: 0;
}

.Year5 {
  position: absolute;
  width: 10.3vw;
  height: 13.3vh;
  left: 82.2vw;
  top: 64.3vh;
  font-family: 'ThunderLight';
  font-style: normal;
  font-weight: 300;
  font-size: 10.5vh;
  line-height: 9.8vh;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0;
}

.CoverImg5 {
  position: absolute;
  width: 32.7vw;
  height: auto;
  left: 10.4vw;
  top: 17.2vh;
}

.Text5 {
  position: absolute;
  width: 16.4vw;
  height: 16.1vh;
  left: 48.9vw;
  top: 77.1vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  color: #FFFFFF;
  margin: 0;
}

.myarrow5 {
  position: absolute;
  left: 72.9vw;
  bottom: 9.3vh;
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.line5 {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #FFFF00;
  margin: 10px 0;
  transition: width 1s ease-out
}

.line5::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .line5:hover {
    width: 15.6vw;
  }
}

.triangle5 {
  display: flex;
  flex-direction: column;
}

.right5 {
  transform: rotate(45deg);
  transform-origin: top right;
  border-right: 0.15rem solid #FFFF00;
  height: 1rem;
}

.left5 {
  transform: rotate(-45deg);
  transform-origin: bottom right;
  border-left: 0.15rem solid #FFFF00;
  height: 1rem;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

@media screen and (max-width: 428px) {

  .Year5 {
    position: absolute;
    width: 16.6vw;
    height: 4.6vh;
    left: 75vw;
    top: 4.6vh;
    font-style: normal;
    font-weight: 300;
    font-size: 4.6vh;
    line-height: 4.3vh;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .FriendFont {
    position: absolute;
    width: 66.6vw;
    height: auto;
    left: 16.6vw;
    top: 14vh;
    margin: 0;
  }


  .CoverImg5 {
    position: absolute;
    width: 80.8vw;
    height: auto;
    left: 15.2vw;
    top: 45.3vh;
  }

  .Text5 {
    position: absolute;
    width: 38vw;
    height: 9vh;
    left: 16.6vw;
    top: 75vh;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .myarrow5 {
    position: absolute;
    left: 52.7vw;
    bottom: 2.6vh;
  }

  .line5 {
    display: inline-block;
    width: 41.6vw;
    height: 2px;
    margin: 10px 0;
  }

}