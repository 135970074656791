@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Thunder-SemiBoldLC';
  src: url('../font/Fonts/Web-PS/Thunder-SemiBoldLC.woff2') format('woff2'),
    url('../font/Fonts/Web-PS/Thunder-SemiBoldLC.otf') format('opentype');

  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.ZingContent {
  width: auto;
  height: var(--doc-height);
  overflow: unset;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
}

.ZingContent::-webkit-scrollbar {
  height: 10;
  visibility: hidden;
}

.ZingHScroll {
  scrollbar-width: thin;
  scrollbar-color: #EFFF31 #7A2ADF;
}

.ZingHScroll::-webkit-scrollbar {
  height: 12px;
}

.ZingHScroll::-webkit-scrollbar-track {
  background-color: #7A2ADF;
}

.ZingHScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #EFFF31;
  border: 2px solid #7A2ADF;
}

.BackArrowZing {
  width: auto;
  height: auto;
  position: fixed;
  right: 84.3vw;
  top: 6.3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.lineBZing {
  display: inline-block;
  width: 13vw;
  height: 2px;
  background-color: #EFFF31;
  margin: 10px 0;
  box-shadow: 0 0 10px 2px #FFFFFF;
  transition: width 1s ease-out
}

.lineBZing::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .lineBZing:hover {
    width: 15.6vw;
  }
}

.triangleBZing {
  display: flex;
  flex-direction: column;
}

.rightBZing {
  transform: rotate(-45deg);
  transform-origin: top left;
  border-left: 0.15rem solid #EFFF31;
  height: 1rem;
  box-shadow: 0 0 10px 2px #FFFFFF;
}

.leftBZing {
  transform: rotate(45deg);
  transform-origin: bottom left;
  border-right: 0.15rem solid #EFFF31;
  height: 1rem;
  box-shadow: 0 0 10px 2px #FFFFFF;
}

@keyframes Width-out {
  0% {
    transform: scaleX(100%);
  }

  100% {
    transform: scaleX(140%);
  }
}

p {
  margin: 0;
}

.Zing1 {
  width: 100vw;
  height: var(--doc-height);
  position: relative;
  background-color: #35E13C;
}

.ZingVLogo {
  position: absolute;
  width: 32.5vw;
  height: auto;
  left: 13vw;
  top: 18vh;
  margin: 0;
}

.Gengar {
  position: absolute;
  width: 21.3vw;
  height: auto;
  left: 33.4vw;
  top: 48vh;
}

.ZingFont {
  position: absolute;
  width: 15vw;
  height: 10vh;
  left: 62vw;
  top: 21.8vh;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12.6vh;
  line-height: 17.2vh;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3E3181;
}

.TextZingC {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 23.2vw;
  height: 48.1vh;
  left: 62vw;
  top: 37.1vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  text-indent: 3.6vw;
  color: #7A2ADF;
}

.FishTank {
  width: 300vw;
  height: var(--doc-height);
  display: flex;
  flex-direction: row;
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #0094FF, #0094FF), linear-gradient(to right, #1C4CF6, #1C4CF6);
  background-position: 0 0, 100vw 0;
  background-size: 100vw var(--doc-height), 200vw var(--doc-height);
}

.FishTankOpencall {
  position: absolute;
  width: 29.6vw;
  height: auto;
  left: 12.5vw;
  top: 7.5vh;
}

.FishTankFont {
  position: absolute;
  width: 25.1vw;
  height: auto;
  left: 50.2vw;
  top: 12.6vh;
}

.TankTube {
  position: absolute;
  width: 5vw;
  height: auto;
  left: 6vw;
  top: 11.4vh;
}

.FishGang {
  position: absolute;
  width: 19.2vw;
  height: auto;
  left: 90vw;
  top: 51.8vh;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.BigTank {
  position: absolute;
  width: 5.8vw;
  height: auto;
  left: 153.6vw;
  top: 6.4vh;
}

.guppy1-1 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 75.4vw;
  top: 13.4vh;
}

.guppy2-8 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 77.8vw;
  top: 9.6vh;
}

.guppy3-7 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 77.8vw;
  top: 16.7vh;
}

.guppy4-2 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 110.3vw;
  top: 19.2vh;
}

.guppy5-4 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 139.2vw;
  top: 50.6vh;
}

.guppy6-3 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 142.1vw;
  top: 145.7vh;
}

.guppy7-5 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 163.4vw;
  top: 79.5vh;
}

.guppy8-6 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 116.3vw;
  top: 72.4vh;
}

.guppy9-4 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 237.8vw;
  top: 51.4vh;
}

.guppy10-3 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 240.8vw;
  top: 46.4vh;
}

.guppy11-8 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 257.2vw;
  top: 16.7vh;
}

.guppy12-1 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 260.5vw;
  top: 11.7vh;
}

.guppy13-7 {
  position: absolute;
  width: 1.4vw;
  height: auto;
  left: 272vw;
  top: 55.7vh;
}

.weed1 {
  position: absolute;
  width: 4.1vw;
  height: auto;
  left: 275vw;
  bottom: 0vh;
}

.weed2 {
  position: absolute;
  width: 5.1vw;
  height: auto;
  left: 281.2vw;
  bottom: 8vh;
  z-index: 9;
}

.TV {
  position: absolute;
  width: 15.2vw;
  height: auto;
  left: 277vw;
  bottom: -7vh;
}

.bubble {
  position: absolute;
  width: 7.7vw;
  height: auto;
  left: 281.8vw;
  bottom: 20vh;
}

.FishTankPic1 {
  position: absolute;
  width: 29.8vw;
  height: auto;
  left: 81.7vw;
  top: 24.8vh;
}

.FishTankPic2 {
  position: absolute;
  width: 24.1vw;
  height: auto;
  left: 115.8vw;
  top: 11vh;
}

.FishTankPic3 {
  position: absolute;
  width: 32.9vw;
  height: auto;
  left: 121.3vw;
  top: 59.3vh;
}

.FishTankPic4 {
  position: absolute;
  width: 57.5vw;
  height: auto;
  left: 171vw;
  top: 5.4vh;
}

.FishTankPic5 {
  position: absolute;
  width: 16.1vw;
  height: auto;
  left: 237.8vw;
  top: 13.9vh;
}

.FishTankPic6 {
  position: absolute;
  width: 28.2vw;
  height: auto;
  left: 239.3vw;
  top: 55.2vh;
}

.FishTankPic7 {
  position: absolute;
  width: 27.9vw;
  height: auto;
  left: 263vw;
  top: 8vh;
}

.FishTankPic8 {
  position: absolute;
  width: 16.3vw;
  height: auto;
  left: 274.7vw;
  top: 48.3vh;
}

.FishTankText1 {
  position: absolute;
  width: 22.6vw;
  height: auto;
  left: 50.2vw;
  top: 56vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  text-indent: 5.2vw;
  color: #BBF120;
}

.FishTankText2 {
  position: absolute;
  width: 23.5vw;
  height: 36.7vh;
  left: 144vw;
  top: 20vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  text-indent: 2.6vw;
  color: #BBF120;
}

.FishTankEnd {
  position: absolute;
  width: 30vw;
  height: 3.8vh;
  left: 298vw;
  top: 2.4vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 5.2vh;
  line-height: 4.7vh;
  display: flex;
  align-items: center;
  color: #BBF120;
  transform: rotate(90deg);
  transform-origin: top left;
}

.ZZTV {
  width: 300vw;
  height: var(--doc-height);
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #7A2ADF, #7A2ADF), linear-gradient(to right, #EFFF31, #EFFF31), url(./ZingMain/ZZTV3BG.jpg);
  background-position: 0 0, 100vw 0, 200vw 0;
  background-size: 100vw var(--doc-height), 100vw var(--doc-height), 100vw var(--doc-height);
}

.fire {
  width: 300vw;
  height: 15vh;
  position: relative;
  background-image: url(./ZZTV/ZZTVGif/fire.gif);
  background-size: 60.2vw 100%;
  background-position: 0 0;
  background-repeat: repeat-x;
}

.ZZTVFont {
  position: absolute;
  width: 20.3vw;
  height: auto;
  left: 6.25vw;
  top: 7.6vh;
}

.evaCongrats {
  position: absolute;
  width: 18.3vw;
  height: auto;
  left: 8.4vw;
  top: 57.6vh;
}

.cctv {
  position: absolute;
  width: 11.4vw;
  height: auto;
  left: 34.5vw;
  top: 7.6vh;
}

.ZZTVtext1 {
  position: absolute;
  width: 26.8vw;
  height: 22.8vh;
  left: 29.6vw;
  top: 37vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  display: flex;
  align-items: center;
  text-align: justify;
  text-indent: 2.6vw;
  font-feature-settings: 'ordn'on;
  color: #FFCD1D;
}

.ZZTVtext2 {
  position: absolute;
  width: 37.6vw;
  height: 12.4vh;
  left: 43vw;
  top: 69.3vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  text-indent: 3.6vw;
  color: #FFCD1D;
}

.ZZTVtext3 {
  position: absolute;
  width: 21.4vw;
  height: 32vh;
  left: 116.2vw;
  top: 13vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  letter-spacing: -0.02em;
  text-indent: 2.6vw;
  color: #1D4DF5;
}

.ZZTVpic1 {
  position: absolute;
  width: 47.6vw;
  height: auto;
  left: 60.3vw;
  top: 12.6vh;
}

.ZZTVpic2 {
  position: absolute;
  width: 24vw;
  height: auto;
  left: 96.1vw;
  top: 65vh;
}

.ZZTVpic3 {
  position: absolute;
  width: 21vw;
  height: auto;
  left: 272.6vw;
  top: 6vh;
}

.Gopro {
  position: absolute;
  width: 12.1vw;
  height: auto;
  left: 126.9vw;
  top: 58.6vh
}

.Iphone {
  position: absolute;
  width: 14.3vw;
  height: auto;
  left: 143.3vw;
  top: 11.8vh;
  transform: rotate(14.5deg);
}

.Laptop {
  position: absolute;
  width: 20.8vw;
  height: auto;
  left: 158.6vw;
  top: 5.8vh;
}

.S1 {
  position: absolute;
  width: 3.1vw;
  height: 6.3vh;
  left: 138vw;
  top: 60vh;
  font-family: Thunder-SemiBoldLC;
  font-style: normal;
  font-weight: 600;
  font-size: 4.2vh;
  line-height: 7.1vh;
  color: #EFFF31;
  text-align: center;
  border-radius: 1.5vw;
  background-color: #7A2ADF;
  box-shadow: 0 0 10px 1px #EFFF31 inset;
}

.S2 {
  position: absolute;
  width: 3.1vw;
  height: 6.3vh;
  left: 143vw;
  top: 19.5vh;
  font-family: Thunder-SemiBoldLC;
  font-style: normal;
  font-weight: 600;
  font-size: 4.2vh;
  line-height: 7.1vh;
  color: #EFFF31;
  text-align: center;
  border-radius: 1.5vw;
  background-color: #7A2ADF;
  box-shadow: 0 0 10px 1px #EFFF31 inset;
}

.S3 {
  position: absolute;
  width: 3.1vw;
  height: 6.3vh;
  left: 161.5vw;
  top: 29.7vh;
  font-family: Thunder-SemiBoldLC;
  font-style: normal;
  font-weight: 600;
  font-size: 4.2vh;
  line-height: 7.1vh;
  color: #EFFF31;
  text-align: center;
  border-radius: 1.5vw;
  background-color: #7A2ADF;
  box-shadow: 0 0 10px 1px #EFFF31 inset;
}

.S4 {
  position: absolute;
  width: 3.1vw;
  height: 6.3vh;
  left: 176vw;
  top: 60.1vh;
  font-family: Thunder-SemiBoldLC;
  font-style: normal;
  font-weight: 600;
  font-size: 4.2vh;
  line-height: 7.1vh;
  color: #EFFF31;
  text-align: center;
  border-radius: 1.5vw;
  background-color: #7A2ADF;
  box-shadow: 0 0 10px 1px #EFFF31 inset;
}

.Steps {
  position: absolute;
  width: 18.3vw;
  height: 32.7vh;
  left: 149.4vw;
  top: 47.7vh;
}

.ZZZTVR31 {
  position: absolute;
  top: 14.1vh;
  left: 180.7vw;
  width: 45.8vw;
  height: 52vh;
}

.thistestrun {
  position: absolute;
  width: 19vw;
  height: 3vh;
  left: 234vw;
  top: 40vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: justify;
  letter-spacing: -0.03em;
  color: #1C4CF6;
}

.Zmith {
  position: absolute;
  width: 11.5vw;
  height: 18.1vh;
  left: 251.9vw;
  top: 15.7vh;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ZZTVROUND {
  position: absolute;
  width: 20.3vw;
  height: 19vh;
  left: 276.6vw;
  top: 56vh;
}

.ZZTVEnd {
  position: absolute;
  width: 30vw;
  height: 9.5vh;
  left: 299.5vw;
  top: 4.7vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 8.4vh;
  line-height: 7.6vh;
  display: flex;
  align-items: center;
  color: #EFFF31;
  transform: rotate(90deg);
  transform-origin: top left;
}

.LZingBoi {
  width: 100vw;
  height: var(--doc-height);
  position: relative;
  background-image: url(./ZingMain/LzingBoiBG.jpg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LZCard {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 67.4vw;
  height: 77vh;
  background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%);
  border-radius: 25px;
  border: 1px outset rgba(255, 255, 255, 0.5);
}

.LZcardNoise {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: 6;
  opacity: 0.5;
}

.LZtext {
  position: absolute;
  width: 67.4vw;
  height: 77vh;
  z-index: 7;
}

.LZcolor1 {
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: color;
  border-radius: 25px;
  z-index: 5;
}

.LZcolor2 {
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: normal;
  border-radius: 25px;
  z-index: 4;
}

.LZspin {
  position: relative;
  width: 41.6vw;
  height: auto;
  padding: 200px 300px 50px 300px;
  filter: blur(20px);
  z-index: 6;
}

.LZspin::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 8;
}

@media (prefers-reduced-motion: no-preference) {
  .LZspin:hover {
    filter: blur(0);
    z-index: 8;
  }
}

.LZcontact {
  position: relative;
  width: 50vw;
  height: 3.2vh;
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  text-align: right;
  z-index: 20;
}

.Contact {
  position: relative;
  color: #E5E5E5;
  margin: 0 3px 0 0;
}

.Zingmail {
  position: relative;
  text-decoration: wavy underline;
  color: #EFFF31;
  margin: 0 0 0 3px;
}

@media screen and (max-width: 428px) {
  .BackArrowZing {
    right: 46.1vw;
    top: 5vh;
  }

  .lineBZing {
    width: 41.6vw;
  }

  .Zing1 {
    width: 200vw;
  }

  .ZingVLogo {
    width: 136.1vw;
    height: auto;
    left: 30.5vw;
    top: 15vh;
  }

  .Gengar {
    width: 43.3vw;
    height: auto;
    left: 88.9vw;
    top: 71.4vh;
  }

  .ZingFont {
    width: 33vw;
    height: 3.9vh;
    left: 22vw;
    top: 45.9vh;
    font-size: 5.9vh;
    line-height: 5vh;
  }

  .TextZingC {
    width: fit-content;
    height: fit-content;
    left: 0;
    top: 0;
    font-size: 3vw;
    line-height: 2.1vh;
  }

  .TextBox1 {
    position: absolute;
    width: 60.2vw;
    height: 17.7vh;
    left: 25.1vw;
    top: 52vh;

  }

  .TextBox2 {
    position: absolute;
    width: 60.2vw;
    height: 19.5vh;
    left: 117.6vw;
    top: 46.5vh;
  }

  .FishTank {
    width: 900vw;
    height: var(--doc-height);
    background-position: 0 0, 300vw 0;
    background-size: 300vw var(--doc-height), 600vw var(--doc-height);
  }

  .TankTube {
    position: absolute;
    width: 8.9vw;
    height: auto;
    left: 7.6vw;
    top: 11.9vh;
  }

  .FishTankOpencall {
    position: absolute;
    width: 116.1vw;
    height: auto;
    left: 21vw;
    top: 12.6vh;
  }

  .FishTankFont {
    position: absolute;
    width: 75.8vw;
    height: auto;
    left: 150vw;
    top: 25vh;
  }

  .FishTankText1 {
    position: absolute;
    width: 73vw;
    height: auto;
    left: 150vw;
    top: 63vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .FishTankText2 {
    position: absolute;
    width: 62vw;
    height: 21.3vh;
    left: 490.2vw;
    top: 19.7vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .FishGang {
    position: absolute;
    width: 51.2vw;
    height: auto;
    left: 275.8vw;
    top: 57.4vh;
  }

  .BigTank {
    display: none;
    visibility: hidden;
  }

  .guppy1-1 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 234.1vw;
    top: 85.6vh;
  }

  .guppy2-8 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 245.8vw;
    top: 81.3vh;
  }

  .guppy3-7 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 245.8vw;
    top: 89.4vh;
  }

  .guppy4-2 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 110.3vw;
    top: 19.2vh;
  }

  .guppy5-4 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 535.6vw;
    top: 78.6vh;
  }

  .guppy6-3 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 550.2vw;
    top: 73.1vh;
  }

  .guppy7-5 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 690.5vw;
    top: 89.2vh;
  }

  .guppy8-6 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 593vw;
    top: 13.3vh;
  }

  .guppy9-4 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 721.2vw;
    top: 14.8vh;
  }

  .guppy10-3 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 735.8vw;
    top: 9.2vh;
  }

  .guppy11-8 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 472.3vw;
    top: 13.1vh;
  }

  .guppy12-1 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 488.4vw;
    top: 7.4vh;
  }

  .guppy13-7 {
    position: absolute;
    width: 6.9vw;
    height: auto;
    left: 818.4vw;
    top: 44.4vh;
  }

  .weed1 {
    position: absolute;
    width: 13.3vw;
    height: auto;
    left: 838.4vw;
  }

  .weed2 {
    position: absolute;
    width: 16.4vw;
    height: auto;
    left: 858.2vw;
    bottom: 6.6vh;
  }

  .TV {
    position: absolute;
    width: 48.2vw;
    height: auto;
    left: 845.1vw;
    bottom: -4.6vh;
  }

  .bubble {
    position: absolute;
    width: 24.3vw;
    height: auto;
    left: 865.1vw;
    bottom: 15.1vh;
  }

  .FishTankPic1 {
    position: absolute;
    width: 135.8vw;
    height: auto;
    left: 232vw;
    top: 11.3vh;
  }

  .FishTankPic2 {
    position: absolute;
    width: 84.8vw;
    height: auto;
    left: 387.4vw;
    top: 25.9vh;
  }

  .FishTankPic3 {
    position: absolute;
    width: 115.1vw;
    height: auto;
    left: 403.3vw;
    top: 60.4vh;
  }

  .FishTankPic4 {
    position: absolute;
    width: 142vw;
    height: auto;
    left: 565.1vw;
    top: 25vh;
  }

  .FishTankPic5 {
    position: absolute;
    width: 65.5vw;
    height: auto;
    left: 734.3vw;
    top: 28vh;
  }

  .FishTankPic6 {
    position: absolute;
    width: 99.4vw;
    height: auto;
    left: 722.8vw;
    top: 60.4vh;
  }

  .FishTankPic7 {
    position: absolute;
    width: 72.8vw;
    height: auto;
    left: 818.4vw;
    top: 11.3vh;
  }

  .FishTankPic8 {
    position: absolute;
    width: 54.1vw;
    height: auto;
    left: 837.9vw;
    top: 45.8vh;
  }

  .FishTankEnd {
    display: none;
    visibility: hidden;
  }

  .ZZTV {
    width: 900vw;
    height: var(--doc-height);
    background-position: 0 0, 300vw 0, 600vw 0;
    background-size: 300vw var(--doc-height), 300vw var(--doc-height), 300vw var(--doc-height);
  }

  .fire {
    width: 900vw;
    height: 10.5vh;
    background-size: auto 100%;
  }

  .ZZTVFont {
    position: absolute;
    width: 84.3vw;
    height: auto;
    left: 13vw;
    top: 12.6vh;
  }

  .evaCongrats {
    position: absolute;
    width: 86.4vw;
    height: auto;
    left: 25.8vw;
    top: 57.4vh;
  }

  .cctv {
    position: absolute;
    width: 38.4vw;
    height: auto;
    left: 126.6vw;
    top: 12.6vh;
  }

  .ZZTVtext1 {
    position: absolute;
    width: 87.5vw;
    height: 14.2vh;
    left: 105vw;
    top: 39.4vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .ZZTVtext2 {
    position: absolute;
    width: 63.8vw;
    height: 13.9vh;
    left: 162vw;
    top: 64.2vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .ZZTVtext3 {
    position: absolute;
    width: 58.4vw;
    height: 20.6vh;
    left: 367.6vw;
    top: 12.9vh;
    font-size: 1.56vh;
    line-height: 2vh;

  }

  .ZZTVpic1 {
    position: absolute;
    width: 145.8vw;
    height: auto;
    left: 200.7vw;
    top: 13.3vh;
  }

  .ZZTVpic2 {
    position: absolute;
    width: 92.3vw;
    height: auto;
    left: 273.5vw;
    top: 59.7vh;
  }

  .ZZTVpic3 {
    position: absolute;
    width: 83.8vw;
    height: auto;
    left: 774.6vw;
    top: 14.5vh;
  }

  .Gopro {
    position: absolute;
    width: 42.8vw;
    height: auto;
    left: 374vw;
    top: 54vh;
  }

  .Iphone {
    position: absolute;
    width: 51.8vw;
    height: auto;
    left: 442vw;
    top: 9vh;
  }

  .Laptop {
    position: absolute;
    width: 54.2vw;
    height: auto;
    left: 515vw;
    top: 7vh;
    transform: rotate(22.55deg);
  }

  .S1 {
    position: absolute;
    width: 8vw;
    height: 3.8vh;
    left: 415vw;
    top: 52vh;
    font-size: 3vh;
    line-height: 4.1vh;
    border-radius: 5vw;
  }

  .S2 {
    position: absolute;
    width: 8vw;
    height: 3.8vh;
    left: 445vw;
    top: 17vh;
    font-size: 3vh;
    line-height: 4.1vh;
    border-radius: 5vw;
  }

  .S3 {
    position: absolute;
    width: 8vw;
    height: 3.8vh;
    left: 512vw;
    top: 20vh;
    font-size: 3vh;
    line-height: 4.1vh;
    border-radius: 5vw;
  }

  .S4 {
    position: absolute;
    width: 8vw;
    height: 3.8vh;
    left: 560vw;
    top: 62vh;
    font-size: 3vh;
    line-height: 4.1vh;
    border-radius: 5vw;
  }

  .Steps {
    position: absolute;
    width: 92vw;
    height: auto;
    left: 439.7vw;
    top: 43vh;
  }

  .ZZZTVR31 {
    position: absolute;
    top: 35vh;
    left: 560vw;
    width: 92.3vw;
    height: 26.6vh;
  }

  .thistestrun {
    position: absolute;
    width: 53.3vw;
    height: 2vh;
    left: 672vw;
    top: 56vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }

  .Zmith {
    position: absolute;
    width: 52.8vw;
    height: 18.9vh;
    left: 680vw;
    top: 19.3vh;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .ZZTVROUND {
    position: absolute;
    width: 84.8vw;
    height: 20.7vh;
    left: 798.2vw;
    top: 61.6vh;
  }

  .ZZTVEnd {
    display: none;
    visibility: hidden;
  }

  .LZingBoi {
    width: 200vw;
    overflow: hidden;
  }

  .LZCard {
    width: 170vw;
    height: 46.6vh;
  }

  .LZtext {
    width: 154.6vw;
    height: 46.6vh;
  }

  .LZspin {
    width: 80vw;
  }

  .LZcontact {
    width: 105vw;
    height: 2vh;
    font-size: 1.56vh;
    line-height: 2vh;
  }
}